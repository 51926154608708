

body {
  margin: 0;
  font-family: 'Sofia Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}
.Main{
  display: flex;
  justify-content: center;
  margin: 10px;
  padding: 50px;
}

/* collections page pagination css */
.NavActive{
  border-bottom: 4px solid #EBAC66;
  width: 100%;

}
.popup {
  border: 1px solid #ffffff !important;
  background: linear-gradient(
    180deg,
    #0b091e00 0%,
    #0b091e00 0.75%,
    #0B091E00 0.21%,
    #0B091E00 0.1%,
    #0b091e00 0.81%,
    #0B091E00 0.4%,
    #0b091e00 0.9%,
    #0B091E00 0%
  ) !important;
  -webkit-backdrop-filter: blur(20px) !important;
  backdrop-filter: blur(20px);
}
.paginationBttns{
  display: flex;
  font-size: 10px;
  height: 40px;
}
.paginationBttns a{
  padding: 10px;
  margin: 8px;
  border-radius: 5px;
  color: rgb(129, 133, 133);
  
}
.paginationBttns a:hover{
  color: rgb(90, 96, 96);
  background-color:#E1B183  ;
}
.paginationActive a{
  background-color:#F2DCC7;
}
.paginationBttns li {
  list-style-type: none;
}


.previousBttn,
.nextBttn {
  list-style-type: none;
}
.swiper {

  width: 100vw;
  height: 100%;
}
.mySwipervideodesigner{
  width: 100%;
  height: 100%; 
}
.mySwiper{
  width: 95%;
}
/* Carousel starts */
/* .swiper {
  width: 100%;
  height: 100%;
}
.mySwiper{
  border-radius: 10px;
  box-shadow: 5px 10px 18px #888888;


}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

 
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
} */


:root {
  --swiper-navigation-color: #342c2c; /* Arrow color */
  --swiper-navigation-background: #b9a177; /* Arrow background color */
  --swiper-navigation-size: 12px; /* Arrow size */
  --swiper-navigation-borderRadius: 20%; 
  --swiper-navigation-padding: 15px;
  --swiper-navigation-marginLeft: 0px;
  --swiper-navigation-height: 10px; /* Adjust height */
  --swiper-navigation-width: 40px; /* Adjust width */
}
.swiper-button-prev {

  --swiper-navigation-color:#342C2C;
  --swiper-navigation-size:12px;
  --swiper-navigation-borderRadius:20%;
  background-color: var(--swiper-navigation-background);
  border-radius: var(--swiper-navigation-borderRadius);
  padding: var(--swiper-navigation-padding);
  margin-left: var(--swiper-navigation-marginLeft);
  height: var(--swiper-navigation-height);
}
.swiper-button-next {
  --swiper-navigation-color:#342C2C;
  --swiper-navigation-size:12px;
  --swiper-navigation-borderRadius:20%;
  background-color: var(--swiper-navigation-background);
  border-radius: var(--swiper-navigation-borderRadius);
  padding: var(--swiper-navigation-padding);
}
.slidebox{

  justify-content: center;
  align-items: center;
}
/* Carousel ends */
/* Video starts*/
.videobox {
  border-radius: 10px;
  width: 800px;
  background-color: white;
  box-shadow: 5px 10px 18px #888888;
  border: 3px solid white;
}
/* Video ends*/

/* Magnifier image */
.productzoomimage{
 border: 3px solid white;
 border-radius: 10px;
 /* height:fit-content; */
 max-height: 460px;
/* object-fit: contain; */


}
.profilepic:hover{
  background-color: #E1B183;

}
.img-container:hover{
  cursor:zoom-in
}
.img-container:hover .texthover{
  display: none;
}
.subimages:hover{
border:1px solid #f7ab60;
  cursor:pointer;
}
.initialImage---Div{
  background-image: url('../../client/src/Assets/Images/Dashboard/profile.png')!important;
  background-repeat: no-repeat;
  background-size: cover;
  transition: transform 0.3s; /* Add a smooth transition effect */

  /* Initial state */
  transform: scale(1);
 background-color: #88888800!important;
}
.control-arrow{
  height: 100px;
  margin-top: auto !important;
  margin-bottom: auto !important; 
}
.slide{

  margin-top: auto !important;
margin-bottom: auto !important; 


}
.initialImage---Div:hover{
  transform: scale(1.1); /* Increase the scale on hover to zoom in */
  cursor: pointer; /* Add a pointer cursor to indicate interactivity */
}

/* modelviewer */
model-viewer {
  width: 500px;
  height: 500px;
}
/* Media query for mobile devices (up to 767px width) */
@media (max-width: 767px) {
  model-viewer {
    width: 300px;
    height: 350px;
  }
}

/* Media query for tablet devices (768px to 1023px width) */
@media (min-width: 768px) and (max-width: 1023px) {
  model-viewer {
    width: 300px;
    height: 350px;
  }
}
.related-products{
  width: 100%;
}
/* designerslider */
.designerSlider{
 height: 370px;
 width: 100%;
}
.storeSlider{

  height: 570px;
width: 100%;
}
/* phone */
@media (max-width: 767px) {
  .storeSlider {
    height: 430px;
   
  }
}
/* Media query for tablet devices (768px to 1023px width) */
@media (min-width: 768px) and (max-width: 1025px) {
  .storeSlider {
    height: 570px;
  }
}


.search {
  position: relative;
}

.search button,
.search span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.search > * > svg {
  display: block;
}
.landingheading{
  -webkit-text-stroke: 0.2px white; 
  font-family: 'Times New Roman', Times, serif;
}
.search span {
  left: 20px;
  opacity: 0.7;
  margin-top: 1px;
}

.search button {
  right: 20px;
  left: auto;
  border: none;
  margin: 0;
  padding: 0;
  background: none;
  cursor: pointer;
  color: #333;
}

.search button:hover {
  color: #f48fb1;
  background: none;

}

.search input {
  box-sizing: border-box;
  display: block;
  /* width: 510px;
  height: 40px; */
  border-radius: 8px;
  padding: 0 50px 0 45px;
  font-size: 18px;
  outline: none;
  /* border: 2px solid #f48fb1; */
  background: white;
}

/* breadcrumps */
.bredname{
  font-weight: bolder;

}
.breadlink:hover{
  text-decoration: underline;
}
/* magnifier */
.popup-media{
  cursor: pointer;
position: fixed;
top: 0;
left: 0;
z-index: 100;
background-color: rgba(0, 0, 0, 0.819);
width: 100%;
height: 100%;

}
.rec-arrow{
  color: #e7b475!important;
  background-color: #285b7100!important;
  
 
 z-index: 2;
  font-size: 16px !important;
width: 10px !important;
}


.popup-media .popupimage{
  /* position: absolute; */
  margin-top: 8%;
  /* left: 35%; */

  transform: translate(-50%, -50%);
  display: block;
  max-width: 70%;
  max-height: 70%;
  object-fit: contain;
  border: 3px solid white;
  border-radius: 10px;

}
@media only screen and (max-width: 1024px) {
  .popup-media .popupimage {
    /* position: absolute; */
  /* top: 15%;
  left: 15%; */
  margin-top: 35%;
  transform: translate(-50%, -50%);
  display: block;
  max-width: 70%;
  max-height: 70%;
  object-fit: contain;
  border: 3px solid white;
  border-radius: 10px;
  }
}

/* phone */
@media (max-width: 750px){
  .popup-media .popupimage {
    /* position: absolute; */
  /* top: 15%;
  left: 15%; */
  margin-top: 50%;
  transform: translate(-50%, -50%);
  display: block;
  max-width: 70%;
  max-height: 70%;
  object-fit: contain;
  border: 3px solid white;
  border-radius: 10px;
  }
}

.popup-media span{
  position: absolute;
  top: 5px;
  right: 20px;
  font-size: 50px;
  font-weight: bolder;
  z-index: 100;
  cursor: pointer;
  color: white !important;
  user-select: none;

}
/* gallery */
.popup-media .popupimagegallery{
  /* position: absolute; */
  margin-top: 5%;
  /* left: 35%; */

  transform: translate(-50%, -50%);
  display: block;
  max-width: 70%;
  max-height: 70%;
  object-fit: contain;
  /* border: 3px solid white; */
  border-radius: 10px;

}
@media only screen and (max-width: 1024px) {
  .popup-media .popupimagegallery {
    /* position: absolute; */
  /* top: 15%;
  left: 15%; */
  margin-top: 10%;
  transform: translate(-50%, -50%);
  display: block;
  max-width: 70%;
  max-height: 70%;
  object-fit: contain;
  /* border: 3px solid white; */
  border-radius: 10px;
  }
}
/* Splash landing */
.videolanding{
width: 230px;

}
@media (max-width: 932px) {
  .videolanding {
    /* border: solid red; */
    width: 200px;
}}
@media (min-width: 768px) and (max-width: 1023px) {
  .videolanding {
    /* border: solid red; */
    width: 230px;
}}

/* scroll text */
.bar_content{
  color: white;
  margin-top: auto;
  font-size: 13px;
}

/* pricing button */
.button-wiggle {
  animation: wiggle 4s 2s infinite;
}

@keyframes wiggle {
	5%,
	50% {
		transform: scale(1);
	}
	10% {
		transform: scale(0.9);
	}
	15% {
		transform: scale(1.15);
	}
	20% {
		transform: scale(1.15) rotate(-5deg);
	}
	25% {
		transform: scale(1.15) rotate(5deg);
	}
	30% {
		transform: scale(1.15) rotate(-3deg);
	}
	35% {
		transform: scale(1.15) rotate(2deg);
	}
	40% {
		transform: scale(1.15) rotate(0);
	}
}
